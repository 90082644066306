$(document).ready(function() {
    let pageTour = localStorage.getItem(page);
    if (!pageTour && page != '') {
        $('#tour-guide').modal('show');
        setTimeout(() => {
            // Slick Selector.
            var slickSlider = $('.slick-slider');
            var maxDots = 5;
            var transformXIntervalNext = -60;
            var transformXIntervalPrev = 60;
    
            slickSlider.on('init', function (event, slick) {
                $(this).find('ul.slick-dots').wrap("<div class='slick-dots-container'></div>");
                $(this).find('ul.slick-dots li').each(function (index) {
                    $(this).addClass('dot-index-' + index);
                });
                $(this).find('ul.slick-dots').css('transform', 'translateX(0)');
                setBoundries($(this), 'default');
            });
    
            var transformCount = 0;
            slickSlider.on('afterChange', function (event, slick, currentSlide) {
                console.log(currentSlide);
                if ((currentSlide + 1) == slick.slideCount) {
                    $('.skip a').html('Done <span class="icon-select-left-arrow mr-10"></span>')
                } else {
                    $('.skip a').html('Skip <span class="icon-select-left-arrow mr-10"></span>')
                }
                // if (currentSlide == 0) {
                //     $('#tour-guide').modal('hide');
                // }
            });
            slickSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                var totalCount = $(this).find('.slick-dots li').length;
                if (totalCount > maxDots) {
                    if (nextSlide > currentSlide) {
                        if ($(this).find('ul.slick-dots li.dot-index-' + nextSlide).hasClass('n-small-1')) {
                            if (!$(this).find('ul.slick-dots li:last-child').hasClass('n-small-1')) {
                                transformCount = transformCount + transformXIntervalNext;
                                $(this).find('ul.slick-dots li.dot-index-' + nextSlide).removeClass('n-small-1');
                                var nextSlidePlusOne = nextSlide + 1;
                                $(this).find('ul.slick-dots li.dot-index-' + nextSlidePlusOne).addClass('n-small-1');
                                $(this).find('ul.slick-dots').css('transform', 'translateX(' + transformCount + 'px)');
                                var pPointer = nextSlide - 3;
                                var pPointerMinusOne = pPointer - 1;
                                $(this).find('ul.slick-dots li').eq(pPointerMinusOne).removeClass('p-small-1');
                                $(this).find('ul.slick-dots li').eq(pPointer).addClass('p-small-1');
                            }
                        }
                    }
                    else {
                        if ($(this).find('ul.slick-dots li.dot-index-' + nextSlide).hasClass('p-small-1')) {
                            if (!$(this).find('ul.slick-dots li:first-child').hasClass('p-small-1')) {
                                transformCount = transformCount + transformXIntervalPrev;
                                $(this).find('ul.slick-dots li.dot-index-' + nextSlide).removeClass('p-small-1');
                                var nextSlidePlusOne = nextSlide - 1;
                                $(this).find('ul.slick-dots li.dot-index-' + nextSlidePlusOne).addClass('p-small-1');
                                $(this).find('ul.slick-dots').css('transform', 'translateX(' + transformCount + 'px)');
                                var nPointer = currentSlide + 3;
                                var nPointerMinusOne = nPointer - 1;
                                $(this).find('ul.slick-dots li').eq(nPointer).removeClass('n-small-1');
                                $(this).find('ul.slick-dots li').eq(nPointerMinusOne).addClass('n-small-1');
                            }
                        }
                    }
                }
            });
            $('button').on('click', function () {
                $('.slick-slider').slick('slickGoTo', 4);
                // gallery.slick('slickGoTo', parseInt(slideIndex));
            });
            $('.skip a').on('click', function () {
                localStorage.setItem(page, true);
                // gallery.slick('slickGoTo', parseInt(slideIndex));
            });
            
            $(".slick-slider").slick({
                infinite: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                autoplaySpeed: 500,
                arrows: true,
                dots: false,
                fade: true,
                prevArrow: "<div class='a-left control-c prev slick-prev prev tour-link'><span class='icon-select-left-arrow ml-10'></span> Previous</div>",
                nextArrow: "<div class='a-right control-c next slick-next next tour-link'>Next <span class='icon-select-left-arrow ml-10'></span></div>",
                onAfterChange: function(slide, index) {
                    console.log(slide);
                }
            });
        }, 300)
    }
   
});

function setBoundries (slick, state) {
    if (state === 'default') {
        slick.find('ul.slick-dots li').eq(4).addClass('n-small-1');
    }
}
